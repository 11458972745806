<template>
  <div class="enterp-level-manege">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div class="col toolbar-title"></div>
        <el-button @click="add" type="primary" size="small">添加等级
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column prop="id" label="编号" width="80" />
        <el-table-column prop="level_name" label="企业等级" />
        <el-table-column label="费用">
          <template slot-scope="scope">
            {{ scope.row.year_price }}/年
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="editLv(scope.row)" size="mini" type="primary">编辑
            </el-button>
            <el-button @click="handleDeleteGoods(scope.row)" size="mini" type="danger">删除
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :page-size="tableData.page_size"
        :layout="MixinTableLayout" :total="tableData.data_total" background>
      </el-pagination>
    </en-table-layout>

    <!--审核详情 dialog-->
    <el-dialog-x-dialog title="添加/编辑" :visible.sync="dialogVisibleg" width="60%" class="dia" :before-close="handleClose">
      <template>
        <el-form style="height: calc(100vh - 300px)" ref="advancedForm" :model="advancedForm" :rules="rules"
          label-width="180px" class="DPT_QY-dialogAdd">
          <el-form-item label="等级名称" prop="level_name">
            <el-input v-model="advancedForm.level_name"></el-input>
          </el-form-item>
          <el-form-item label="年费" prop="year_price">
            <el-input v-model="advancedForm.year_price"></el-input>
          </el-form-item>
          <el-form-item label="续费设置" prop="renew">
            <table border>
              <tr style="text-align: center;">
                <th>续费年限</th>
                <th v-for="item in advancedForm.renewals_setting_vo.year_discount_volist" :key="item.renewal_period">
                  {{ item.renewal_period }}年
                </th>
              </tr>
              <tr style="text-align: center;">
                <td style="width: 120px;">费用（折扣）</td>
                <td v-for="(item, index) in advancedForm.renewals_setting_vo.year_discount_volist"
                  :key="item.renewal_period">
                  <el-input ref="discount" v-model="item.discount" @input="handleInput(item.discount, item)">
                    <template slot="append">
                      折/年
                    </template>
                  </el-input>
                </td>
              </tr>
            </table>
            <span style="color: #d4d4d4; font-size: 14px;">说明：续费金额会按照1年的年费*折扣*续费年数来计算。</span>
          </el-form-item>
          <el-form-item label="等级描述" prop="level_dec">
            <el-input v-model="advancedForm.level_dec"></el-input>
          </el-form-item>
          <el-form-item label="拥有商城数量" prop="store_num">
            <el-input :placeholder="advancedForm.store_num ? '' : '不填写默认为数量不限'" v-model.number="advancedForm.store_num"
              @input="handleNumberInput(advancedForm.store_num, 1)"></el-input>
          </el-form-item>
          <el-form-item label="拥有套餐卡商城数量" prop="store_num">
            <el-input :placeholder="advancedForm.package_store_num ? '' : '不填写默认为数量不限'"
              v-model.number="advancedForm.package_store_num"
              @input="handleNumberInput(advancedForm.package_store_num, 2)"></el-input>
          </el-form-item>
          <!--          <el-form-item-->
          <!--            label="可制套餐卡型号数量"-->
          <!--            prop="package_num"-->
          <!--          >-->
          <!--            <el-input-->
          <!--              v-model="advancedForm.package_num"-->
          <!--              :placeholder="advancedForm.package_num?'':'不填写默认为数量不限'"-->
          <!--            ></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label="可添加员工账户数量" prop="staff_num">
            <el-input v-model.number="advancedForm.staff_num" :placeholder="advancedForm.staff_num ? '' : '不填写默认为数量不限'"
              @input="handleNumberInput(advancedForm.staff_num, 3)"></el-input>
          </el-form-item>
          <el-form-item label="是否有供应商应用" prop="has_supplier">
            <template>
              <el-radio v-model="advancedForm.has_supplier" :label="1">是
              </el-radio>
              <el-radio v-model="advancedForm.has_supplier" :label="0">否
              </el-radio>
            </template>
          </el-form-item>
          <el-form-item label="是否有现金商城应用" prop="has_cash_mall">
            <template>
              <el-radio v-model="advancedForm.has_cash_mall" :label="1">是</el-radio>
              <el-radio v-model="advancedForm.has_cash_mall" :label="0">否</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="是否有套餐卡商城应用" prop="has_package_store">
            <template>
              <el-radio v-model="advancedForm.has_package_store" :label="1">是</el-radio>
              <el-radio v-model="advancedForm.has_package_store" :label="0">否</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="是否有智能套餐卡商城应用" prop="has_intelligent_card20">
            <template>
              <el-radio v-model="advancedForm.has_intelligent_card20" :label="1">是</el-radio>
              <el-radio v-model="advancedForm.has_intelligent_card20" :label="0">否</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="是否有方案报价应用" prop="has_programme">
            <template>
              <el-radio v-model="advancedForm.has_programme" :label="1">是
              </el-radio>
              <el-radio v-model="advancedForm.has_programme" :label="0">否
              </el-radio>
            </template>
          </el-form-item>
          <el-form-item label="付费定制开发优惠" prop="customized_discount">
            <el-select v-model="advancedForm.customized_discount" placeholder="无优惠">
              <el-option v-for="item in discount" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="自有商品订单服务费" prop="self_goods_price_vo.price_type">
            <template>
              <el-radio v-model="advancedForm.self_goods_price_vo.price_type" :label="1">自定义
              </el-radio>
              <el-radio v-model="advancedForm.self_goods_price_vo.price_type" :label="0">不限
              </el-radio>

            </template>
          </el-form-item>

          <el-form-item class="ifom" prop="servicePrice" label="设置服务费"
            v-show="advancedForm.self_goods_price_vo.price_type === 1">
            <table border>
              <tr>
                <th width="80">单数</th>
                <th v-for="(k, idx) in advancedForm.self_goods_price_vo.order_price_list" :key="idx"
                  style="text-align: center;">{{ k.order_num }}
                </th>
              </tr>
              <tr>
                <td style="text-align: center;">费用</td>
                <td v-for="(d, index) in advancedForm.self_goods_price_vo.order_price_list" :key="index"
                  style="text-align: center;">
                  <el-input ref="price" placeholder="请输入价格" v-model="d.price" @input="handleServicePrice(d.price, d)">
                  </el-input>
                </td>
              </tr>
            </table>
          </el-form-item>
          <el-form-item label="其他服务" prop="other">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容" :maxlength="120"
              v-model="advancedForm.other">
            </el-input>
          </el-form-item>

          <el-form-item class="bottom-btn-box" style="text-align:right;">
            <el-button @click="dialogVisibleg = false" size="small">取 消
            </el-button>
            <el-button type="primary" @click="addLv" size="small">确 定
            </el-button>
          </el-form-item>
        </el-form>
      </template>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_shops from "@/api/shop";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";

export default {
  components: { EnTableLayout },
  data () {
    return {
      value: null,
      radio: "1",
      dialogVisible: false,
      dialogVisibleb: false,
      dialogVisibleg: false,
      aaa: 0,
      /** 列表loading状态 */
      loading: false,
      goodsType: 1,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
      },

      /** 商品列表数据 */
      tableData: {},
      tableData11: [{}, {}],
      rules: {
        level_name: [
          { required: true, message: '等级名称为必填项', trigger: 'blur' }
        ],
        year_price: [
          { required: true, message: '年费为必填项', trigger: 'blur' }
        ],
        renew: [
          {
            required: true, validator: (rules, value, callback) => {
              if (!this.$refs.discount[0].value || !this.$refs.discount[1].value || !this.$refs.discount[2].value) {
                callback(new Error('续费年限折扣为必填项'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        servicePrice: [
          {
            required: true, validator: (rules, value, callback) => {
              if (this.advancedForm.self_goods_price_vo.price_type === 1) {
                if (!this.$refs.price[0].value || !this.$refs.price[1].value || !this.$refs.price[2].value || !this.$refs.price[3].value) {
                  callback(new Error('自有商品订单服务费为必填项'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      },
      advancedForm: {
        level_name: "",
        year_price: "",
        level_dec: "",
        store_num: "",
        package_num: "",
        package_store_num: "",
        has_cash_mall: 1,
        has_package_store: 0,
        has_intelligent_card20: 0,
        staff_num: "",
        has_supplier: 0,
        has_programme: 0,
        customized_discount: "",
        other: "",
        self_goods_price_vo: {
          price_type: 1,
          order_price_list: [
            { order_num: "0_100", price: "0" },
            { order_num: "101_500", price: "0" },
            { order_num: "501_3000", price: "0" },
            { order_num: "3000以上", price: "0" },
          ],
        },
        renewals_setting_vo: {
          year_discount_volist: [
            { discount: '10', discount_price: '', renewal_period: '1' },
            { discount: '10', discount_price: '', renewal_period: '2' },
            { discount: '10', discount_price: '', renewal_period: '3' },
          ]
        }
      },
      oldAdvancedForm: {
        level_name: "",
        year_price: "",
        level_dec: "",
        store_num: "",
        package_num: "",
        package_store_num: "",
        has_cash_mall: 1,
        has_package_store: 0,
        has_intelligent_card20: 0,
        staff_num: "",
        has_supplier: 0,
        has_programme: 0,
        customized_discount: "",
        other: "",
        self_goods_price_vo: {
          price_type: 0,
          order_price_list: [
            { order_num: "0_100", price: "0" },
            { order_num: "101_500", price: "0" },
            { order_num: "501_3000", price: "0" },
            { order_num: "3000以上", price: "0" },
          ],
        },
        renewals_setting_vo: {
          year_discount_volist: [
            { discount: '10', discount_price: '', renewal_period: '1' },
            { discount: '10', discount_price: '', renewal_period: '2' },
            { discount: '10', discount_price: '', renewal_period: '3' },
          ]
        }
      },
      options: [],
      discount: [
        { label: "无优惠", value: 0 },
        { label: "9.5折", value: 9.5 },
        { label: "9折", value: 9.0 },
        { label: "8.5折", value: 8.5 },
        { label: "8折", value: 8.0 },
        { label: "7.5折", value: 7.5 },
        { label: "7折", value: 7.0 },
        { label: "6.5折", value: 6.5 },
        { label: "6折", value: 6.0 },
      ],
      id: "",
    };
  },
  watch: {
    'advancedForm.self_goods_price_vo.price_type' (val) {
      this.rules.servicePrice[0].required = val === 1
    }
  },
  mounted () {
    this.GET_List();
  },
  activated () {
    this.GET_List();
  },
  methods: {
    handleNumberInput (val, type) {
      const reg = /^[0-9]\d*$/
      if (!reg.test(val)) {
        if (type === 1) {
          this.advancedForm.store_num = ''
        } else if (type === 2) {
          this.advancedForm.package_store_num = ''
        } else {
          this.advancedForm.staff_num = ''
        }
      }
    },
    handleServicePrice (val, item) {
      const reg = /^[0-9]\.[0-9]{2}$|^[0-9]\d*$/
      if (val.includes('.')) {
        if (!reg.test(val)) {
          const pointBefore = val.split('.')[0]
          const pointAfter = val.split('.')[1]
          if (pointAfter.length > 2) {
            item.price = pointBefore + '.' + pointAfter.slice(0, 2)
          } else {
            if (!reg.test(pointAfter)) {
              item.price = pointBefore + '.'
            } else {
              item.price = pointBefore + '.' + pointAfter
            }
          }
        }
      } else {
        if (!reg.test(val)) {
          item.price = ''
        }
      }
    },
    handleInput (val, item) {
      const reg = /^[0-9]\.[0-9]$|^[1][0]$|^[0-9]$/
      if (val.includes('.')) {
        if (!reg.test(val)) {
          const pointBefore = val.split('.')[0]
          const pointAfter = val.split('.')[1]
          if (pointBefore === '10') return item.discount = pointBefore;
          if (pointAfter.length > 1) {
            item.discount = pointBefore + '.' + pointAfter[0]
          } else {
            if (!reg.test(pointAfter)) {
              item.discount = pointBefore + '.'
            } else {
              item.discount = pointBefore + '.' + pointAfter
            }
          }
        }
      } else {
        if (!reg.test(val)) {
          item.discount = ''
        }
      }
    },
    //打开新增弹窗
    add () {
      this.id = "";
      this.dialogVisibleg = true;
      this.advancedForm = this.oldAdvancedForm;
    },
    //保存企业等级弹窗
    addLv () {
      this.$refs.advancedForm.validate(res => {
        if (res) {
          //判断现金商城、套餐卡商城、智能套餐卡商城是否都关闭   
          if (this.advancedForm.has_cash_mall == 0 && this.advancedForm.has_package_store == 0 && this.advancedForm.has_intelligent_card20 == 0) {
            this.$message.error('现金商城应用、套餐卡商城应用、选择项不可全部关闭，需至少保留一项开启。')
          } else {
            if (!this.id) {
              //重新创建一个
              API_shops.addLv(this.advancedForm).then((res) => {
                this.dialogVisibleg = false;
                this.GET_List();
                this.id = "";
              });
            } else {
              //编辑某个id下的
              API_shops.editLv(this.id, this.advancedForm).then((res) => {
                this.GET_List();
                this.dialogVisibleg = false;
                this.id = "";
              });
            }
          }
        } else {
          this.$message.error('请检查表单输入项')
        }
      })
      console.log(this.advancedForm);
    },
    //打开编辑弹窗
    editLv (row) {
      this.id = row.id;
      API_shops.getLevelDetail(row.id).then((res) => {
        this.dialogVisibleg = true;
        if (!res.renewals_setting_vo) {
          res['renewals_setting_vo'] = {
            year_discount_volist: [
              { discount: '', discount_price: '', renewal_period: '1' },
              { discount: '', discount_price: '', renewal_period: '2' },
              { discount: '', discount_price: '', renewal_period: '3' },
            ]
          }
        }
        res.package_num = res.package_num <= 0 ? "" : res.package_num;
        res.store_num = res.store_num <= 0 ? "" : res.store_num;
        res.staff_num = res.staff_num <= 0 ? "" : res.staff_num;
        res.package_store_num = res.package_store_num <= 0 ? "" : res.package_store_num
        this.advancedForm = res;
        console.log(this.advancedForm)
      });
    },
    handleClose () {
      this.aaa = 0;
      this.dialogVisible = false;
      this.dialogVisibleb = false;
      this.dialogVisibleg = false;
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 删除企业等级分类 */
    handleDeleteGoods (row) {
      this.$confirm("确认删除吗, 是否继续?", "提示", { type: "warning" }).then(
        () => {
          API_shops.delLevel(row.id, { id: row.id }).then((res) => {
            this.$message({
              showClose: true,
              message: "删除成功",
              type: "success",
            });
            this.GET_List();
          });
        }
      );
    },
    GET_List () {
      API_shops.getLevelLista(this.params).then((res) => {
        this.tableData = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn-box {
  position: absolute;
  right: 25px;
  bottom: -10px;
}

.DPT_QY-dialogAdd {
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 60px;
  padding-right: 50px;
}

/deep/ .el-form-item {
  .el-input-number {
    width: 100%;

    .el-input__inner {
      text-align: left;
    }
  }
}
</style>
